export const baseViewFormConfig = {
  formItems: [
    {
      field: 'aid',
      type: 'input',
      label: 'param.aid',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        disabled: true
      }
    }
  ]
}
